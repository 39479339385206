^<template>
    <div class="modal retirement-calculator-modal" :class="{ active: modal }">
        <div class="modal-card">
            <RetirementCalculator :in_modal="true" @cancel="cancel()" @close_modal="close_modal($event)" />
        </div>
    </div>
</template>

<script>
import RetirementCalculator from '../../../pages/RetirementCalculator/RetirementCalculator.vue';

export default {
    name: 'RetirementCalculatorModal',

    components: { RetirementCalculator },

    props: {
        show: {
            type: Boolean,
            default: false
        },
    },

    watch: {
        show: function(val) {
            this.modal = val;
        }
    },

    data() {
        return {
            modal: false,
        }
    },
    
    methods: {
        close_modal: function(event) {
            const self = this;

            self.modal = false;
            self.$emit('close_modal', event)
        },


        cancel: function() {
            const self = this;

            self.modal = false;
            self.$emit('cancel');
        }
    },
}
</script>

