<template>
    <div class="step-2-worker" id="step-2-worker">
        <Stepper :numberOfSteps="number_of_steps" :step="step" :top="true"/>

        <div v-if="step === 1" id="step-1" class="card card-split">
            <div class="card-heading">
                <p>{{$t('workerOffer.card.split.available.title')}}</p>
            </div>

            <div class="card-body">
                <div class="question-wrapper">
                    <div :class="['question availability', worker.selected_job_type]">
                        <div class="buttons-container">
                            <div class="label-container">
                                <p>{{$t('getstarted.steps.two.worker.splits.one.select.title')}}</p>
                            </div>

                            <div class="buttons">
                                <span id="selected-job-type-permanent" :class="['cta cta-select no-hover', { active: worker.selected_job_type === 'permanent' }]" @click="worker.selected_job_type = 'permanent'">
                                    <font-awesome-icon v-if="worker.selected_job_type === 'permanent'" class="success" :icon="['fa', 'circle-check']" />
                                    <span v-else class="circle"></span>                                    
                                    <p>{{ $t('getstarted.steps.two.worker.splits.one.select.permanent') }}</p>
                                </span>

                                <span id="selected-job-type-temporary" :class="['cta cta-select', { active: worker.selected_job_type === 'temporary' }]" @click="worker.selected_job_type = 'temporary'">
                                    <font-awesome-icon v-if="worker.selected_job_type === 'temporary'" class="success" :icon="['fa', 'circle-check']" />
                                    <span v-else class="circle"></span>                                    
                                    <p>{{ $t('getstarted.steps.two.worker.splits.one.select.temporary') }}</p>
                                </span>
                            </div>
                        </div>

                        <div class="availability-dates" v-if="worker.selected_job_type === 'permanent'">   
                            <div class="label-container">
                                <p>{{$t('availableNowLabel')}}</p>
                            </div>

                            <div class="button-group collapsed">
                                <button id="available-now-choice" :class="['cta cta-option', { active: worker.availableNow }]" @click="worker.availableNow = true">{{$t('language.yes')}}</button>
                                <button id="not-available-now-choice" :class="['cta cta-option', { active: !worker.availableNow }]" @click="worker.availableNow = false">{{$t('language.no')}}</button>
                            </div>
                            
                            <div v-if="worker.availableNow === false" class="question next-time-available">
                                <p>{{$t('nextTimeAvailable')}}</p>
                                
                                <VueDatePicker  id="next-time-available"
                                                v-model="worker.nextTimeAvailable" 
                                                :min-date="today"
                                                :locale="{lang: currentLanguage}"
                                                color="#303030" />

                                <FormError :data="validation.worker.nextTimeAvailable" />
                            </div>
                        </div>

                        <div class="question times-available" v-if="worker.selected_job_type === 'temporary'">   
                            <div class="inputs">
                                <div class="input-container">
                                    <div class="label-container">
                                        <p>{{$t('getstarted.steps.two.worker.splits.one.times-available.nextTimeAvailable')}}</p>
                                    </div>
                                    
                                    <VueDatePicker  id="next-time-available"
                                                    v-model="worker.nextTimeAvailable" 
                                                    :min-date="today"
                                                    :locale="{lang: currentLanguage}"
                                                    color="#303030" />
                                </div>

                                <div class="input-container">
                                    <div class="label-container">
                                        <p>{{$t('getstarted.steps.two.worker.splits.one.times-available.availableUntil')}}</p>
                                    </div>

                                    <VueDatePicker  id="available-until"
                                                    v-model="worker.availableUntil" 
                                                    :min-date="today"
                                                    :locale="{lang: currentLanguage}"
                                                    color="#303030" />
                                </div>
                            </div>
                            <p class="notice">{{ $t('getstarted.steps.two.worker.splits.one.times-available.notice') }}</p>
                        </div>
                        
                    </div>
                    <!-- <div class="question eligibilty">
                        <div class="label-container light">
                            <p>{{$t('eligible_for_offers_reserved_for_students')}}</p>
                        </div>

                        <div class="button-group collapsed">
                            <button id="available-now-choice" :class="['cta cta-option', { active: worker.eligible_for_offers_reserved_for_students }]" @click="worker.eligible_for_offers_reserved_for_students = true">{{$t('language.yes')}}</button>
                            <button id="not-available-now-choice" :class="['cta cta-option', { active: !worker.eligible_for_offers_reserved_for_students }]" @click="worker.eligible_for_offers_reserved_for_students = false">{{$t('language.no')}}</button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        
        <div v-if="step === 2" id="step-2" class="card card-split">
            <div class="card-heading">
                <p class="title">{{$t('workProviderOffer.shared.card.split.schedule.title')}}</p>
            </div>
            
            <div class="card-body">
                <div class="question-wrapper">
                    <WorkDaysComponent  :workDays="offer.workDays"
                                        :workTimes="offer.workTimes"
                                        :validation="validation.offer"
                                        client="worker"
                                        @update="update($event)" />
                </div>
            </div>
        </div>

        <div v-if="step === 3" id="step-3" class="card card-split">
            <div class="card-heading">
                <p class="title">{{$t('workProviderOffer.shared.card.split.schedule.title')}}</p>
            </div>
            
            <div class="card-body">
                <div class="question-wrapper">
                    <WorkTimesComponent  v-if="offer.workDays"
                                        :workDays="offer.workDays"
                                        :workTimes="offer.workTimes"
                                        :hoursPerWeek="offer.hoursPerWeek"
                                        :validation="validation.offer"
                                        client="worker"
                                        @update="update($event)" />
                </div>
            </div>
        </div>
        
        <!-- <div v-if="step === 4" id="step-4" class="card card-split">
            <div class="card-heading">
                <p>{{$t('workerOffer.card.split.location.title')}}</p>
            </div>

            <div class="card-body">
                <div class="question-wrapper">
                    <div class="question work-location column">
                        <div class="label-container">
                            <p>{{$t('workLocationTitle')}}</p>
                        </div>

                        <div class="checkbox-container">
                            <div class="checkbox-with-label" @click="regularOption = !regularOption">
                                <input id="checkbox-regular-option" type="checkbox" :class="[{checked: regularOption}, error('offer', 'workLocation')]" v-model="regularOption">
                                <p class="label">{{$t('regular')}}</p>
                            </div>
                            <div class="checkbox-with-label" @click="remoteOption = !remoteOption">
                                <input id="checkbox-remote-option" type="checkbox" :class="[{checked: remoteOption}, error('offer', 'workLocation')]" v-model="remoteOption">
                                <p class="label">{{$t('remote')}}</p>
                            </div>
                            <div class="checkbox-with-label" @click="hybridOption = !hybridOption">
                                <input id="checkbox-hybrid-option" type="checkbox" :class="[{checked: hybridOption}, error('offer', 'workLocation')]" v-model="hybridOption">
                                <p class="label">{{$t('hybride')}}</p>
                            </div>
                            <div class="checkbox-with-label" @click="bothOption = !bothOption">
                                <input id="checkbox-both-option" type="checkbox" :class="[{checked: bothOption}, error('offer', 'workLocation')]" v-model="bothOption">
                                <p class="label">{{$t('both')}}</p>
                            </div>
                        </div>

                        <FormError :data="validation.offer.workLocation" />
                    </div>

                    <HybridComponent    v-if="hybrid.hybrid"
                                        :hybrid="hybrid"
                                        :validation="validation"
                                        @update="update($event)" />

                    <div v-if="offer.workLocation === 'regular' || offer.workLocation === 'both'" class="question distance-range">
                        <div class="label-container">
                            <p>{{$t('distanceRangeTitle')}}</p>
                            <FormError :data="validation.worker.distanceRange" :classes="[{ desktop: true }, { responsive: true }]" />
                        </div>

                        <div class="input-container">
                            <the-mask :class="['input input-text', error('worker', 'distanceRange')]" :mask="['# km', '## km', '### km']" v-model.number="worker.distanceRange" />
                        </div>

                        <FormError :data="validation.worker.distanceRange" :classes="[{ mobile: true }, { responsive: true }]" />
                    </div>
                </div>
            </div>
        </div> -->

        <div v-if="step === 4" id="step-4" class="card card-split">
            <div class="card-heading with-subtitle">
                <p class="title">{{$t('workerOffer.card.split.classification.title')}}</p>
                <p v-if="offer.classification.all === false" class="subtitle">{{$t('workProviderOffer.shared.card.split.classification.subtitle')}}</p>
            </div>

            <div class="card-body">
                <div class="question-wrapper">
                    <div class="question choose-classification column">
                        <div class="label-container">
                            <p class="title">{{$t('employmentSituations.title')}}</p>
                        </div>

                        <multiselect    v-model="chooseClassification" 
                                        :options="chooseClassificationOptions"
                                        :close-on-select="true"
                                        :multiple="false"
                                        :allowEmpty="false"
                                        :searchable="false"
                                        :class="error('general', 'chooseClassification')"
                                        
                                        :placeholder="$t('employmentSituations.placeholder')"
                                        :select-label="$t('language.multiselect.label.select')"
                                        :selected-label="$t('language.multiselect.label.selected')"
                                        :deselect-label="$t('language.multiselect.label.deselect')"
                                        :custom-label="translate_choose_classification"> 
                
                                        <template slot="noResult">{{$t('language.multiselect.label.no_result')}}</template>
                                        <template slot="noOptions">{{$t('language.multiselect.label.no_options')}}</template>
                        </multiselect>

                        <FormError :data="validation.general.chooseClassification" />

                        <p v-if="chooseClassification === 'no'" class="all-classifications"><font-awesome-icon :icon="['fa', 'circle-check']" />{{ $t('workerOffer.card.create.classification.choose.no') }}</p>
                    </div>

                    <ClassificationComponent    v-if="chooseClassification && offer.classification.all === false"
                                                :classification="offer.classification"
                                                :validation="validation.offer"
                                                @update="update($event)" />
                </div>
            </div>
        </div>
        
        <div v-if="step === 5" id="step-5" class="card card-split salary">
            <div class="card-heading">
                <p class="title">{{$t('workProviderOffer.shared.card.split.compensation.title')}}</p>
            </div>  

            <div class="card-body">
                <div class="question-wrapper">
                    <div class="question salary">
                        <div class="label-container">
                            <p>{{$t('workerOffer.card.create.salary.label')}}</p>
                            <FormError :data="validation.offer.salary" />
                        </div>
                        
                        <div class="input-button-container">
                            <div class="group">
                                <the-mask id="input-salary" v-if="offer.salary.type === 'hourly'" :class="['input input-text', error('offer', 'salary')]" :mask="['## $', '##,## $', '### $', '###,## $']" v-model="offer.salary.amount" />
                                <the-mask id="input-salary" v-else :class="['input input-text', error('offer', 'salary')]" :mask="['#### $', '##,### $', '###,### $']" v-model="offer.salary.amount" />

                                <div class="button-group">
                                    <button id="salary-type-yearly" :class="['cta cta-option', { active: offer.salary.type === 'yearly' }]" @click="offer.salary.type = 'yearly'">{{$t('workerOffer.card.create.salary.types.yearly')}}</button>
                                    <button id="salary-type-hourly" :class="['cta cta-option', { active: offer.salary.type === 'hourly' }]" @click="offer.salary.type = 'hourly'">{{$t('workerOffer.card.create.salary.types.hourly')}}</button>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>

                <!-- <div v-if="!from_retirement_calculator" :class="['trigger-cta-container preferences']">
                    <button id="recommentations-salary-modal-trigger" class="cta cta-secondary cta-slim" @click="recommendations.salary.modal = true">{{$t('workerOffer.card.create.salary.suggest')}}</button>

                    <div class="notice-group">
                        <p class="notice">{{ $t('retirement_calculator.onboarding.notice') }}</p>
                        <button id="retirement-modal-trigger" class="cta cta-outline dark cta-slim" @click="retirement_modal.modal = true">{{$t('retirement_calculator.onboarding.modal.trigger')}}</button>
                    </div>
                </div> -->

                <!-- <div v-if="from_retirement_calculator" class="retirement-maximise-salary">
                    <p class="title">{{$t('retirement_calculator.get-started.maximise.title')}}</p>
                    <div v-if="offer.salary.type === 'hourly' && hourly_salary_to_yearly !== 0" class="retirement_amount salary-yearly">
                        <p>{{$t('retirement_calculator.get-started.maximise.yearly_salary')}}</p>
                        <span class="amount">{{$utils._format_yearly_cash(hourly_salary_to_yearly)}}</span>
                    </div>
                    
                    <div class="retirement_amount">
                        <p>{{$t('retirement_calculator.get-started.maximise.retirement_amount')}}</p>
                        <span class="amount">{{$utils._format_yearly_cash(retirement_amount)}}</span>
                    </div>

                    <div v-if="offer.salary.type === 'yearly' && offer.salary.amount > retirement_amount">
                        <span class="attention" v-html="$t('retirement_calculator.get-started.maximise.errors.annual', { entered_amount: $utils._format_yearly_cash(offer.salary.amount), retirement_amount: $utils._format_yearly_cash(retirement_amount) })" />
                    </div>

                    <div v-if="offer.salary.type === 'hourly' && hourly_salary_to_yearly > retirement_amount">
                        <span class="attention" v-html="$t('retirement_calculator.get-started.maximise.errors.hourly', { entered_amount: formatted_hourly_salary(offer.salary.amount, true), hours: offer.hoursPerWeek, annual: $utils._format_yearly_cash(hourly_salary_to_yearly), retirement_amount: $utils._format_yearly_cash(retirement_amount) })" />
                    </div>

                    <div class="trigger-cta-container">
                        <button class="cta cta-secondary cta-slim" @click="recommendations.salary.modal = true">{{$t('workerOffer.card.create.salary.suggest')}}</button>
                        <button class="cta cta-outline dark cta-slim" @click="retirement_modal.modal = true">{{$t('retirement_calculator.onboarding.modal.retrigger')}}</button>
                    </div> 
                </div> -->
            </div>
        </div>

        <div v-if="step === 6" id="step-6" class="card card-split">
            <div class="card-heading with-subtitle">
                <p class="title">{{$t('getstarted.steps.seven.title')}}</p>
                <p class="subtitle" v-html="$t('getstarted.steps.seven.subtitle')"></p>
            </div>

            <div class="card-body">
                <div class="question-wrapper">
                    <div class="question allowed-contact-methods allowed-contact-methods-worker">
                        <div class="row">
                            <div class="buttons-container">
                                <span id="allowed-contact-method-app" :class="['cta cta-select no-hover', { active: true }]" @click="display_notice()">
                                    <font-awesome-icon class="success" :icon="['fa', 'circle-check']" />
                                    <p>{{ $t('getstarted.steps.seven.buttons.app') }}</p>
                                </span>

                                <span id="allowed-contact-method-email" :class="['cta cta-select', { active: worker.allowed_contact_methods.email.allowed }]" @click="worker.allowed_contact_methods.email.allowed = !worker.allowed_contact_methods.email.allowed">
                                    <font-awesome-icon v-if="worker.allowed_contact_methods.email.allowed" class="success" :icon="['fa', 'circle-check']" />
                                    <span v-else class="circle"></span>                                    
                                    <p>{{ $t('getstarted.steps.seven.buttons.email') }}</p>
                                </span>

                                <span id="allowed-contact-method-phone" :class="['cta cta-select nomargin-bottom', { active: worker.allowed_contact_methods.phone.allowed }]" @click="worker.allowed_contact_methods.phone.allowed = !worker.allowed_contact_methods.phone.allowed">
                                    <font-awesome-icon v-if="worker.allowed_contact_methods.phone.allowed" class="success" :icon="['fa', 'circle-check']" />
                                    <span v-else class="circle"></span>
                                    <p>{{ $t('getstarted.steps.seven.buttons.phone') }}</p>
                                </span>
                            </div>

                            <span v-if="worker.allowed_contact_methods.phone.allowed" class="divider mh responsive desktop"></span>
                            
                            <div id="allowed-contact-method-phone-input-container" v-if="worker.allowed_contact_methods.phone.allowed" class="phone-number-container">
                                <div class="input-container label-on-top">
                                    <p :class="error('worker', 'phone_number')">{{ $t('allowed_contact_methods.fields.phone_number.title') }}</p>
                                    <the-mask id="allowed-contact-method-phone-input" mask="+1 (###) ###-####" v-model="worker.allowed_contact_methods.phone.value" :class="['input input-text', error('worker', 'phone_number')]" placeholder="+1 (123) 456-7890"/>
                                </div>

                                <FormError :data="validation.worker.phone_number" :classes="[{desktop: true}, {responsive: true}]" />

                                <span id="allowed-contact-method-phone-supports-texts" :class="['cta cta-select', { active: worker.allowed_contact_methods.phone.supports_texts }]" @click="worker.allowed_contact_methods.phone.supports_texts = !worker.allowed_contact_methods.phone.supports_texts">
                                    <font-awesome-icon v-if="worker.allowed_contact_methods.phone.supports_texts" class="success" :icon="['fa', 'circle-check']" />
                                    <span v-else class="circle"></span>
                                    <p>{{ $t('getstarted.steps.seven.supports_texts') }}</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div v-if="step === 8" id="step-8" class="card card-split">
            <div class="card-heading">
                <p class="title">{{$t('workerOffer.card.create.benefits.title')}}</p>
            </div>

            <div class="card-body">
                <div class="question-wrapper">
                    <BenefitsComponent  :benefits="benefits" />
                </div>
                
                <div class="trigger-cta-container benefits">
                    <button class="cta cta-secondary cta-slim" @click="recommendations.benefits.modal = true">{{$t('workerOffer.card.create.benefits.suggest')}}</button>
                </div>
            </div>
        </div> -->
        
        <div class="arrows">
            <div :class="['card-footer final']">
                <div class="button-wrapper">
                    <button v-if="step === 1" id="get_started_back_step_2" :class="['cta cta-circle cta-outline dark arrow bx-s', { hide: !showBackButton }]" @click="change_current_step(-1)"><font-awesome-icon :icon="['fa', 'arrow-left']" /></button>
                    <button v-else id="get_started_step_2_previous" :class="['cta cta-circle cta-outline dark arrow bx-s', { hide: !showBackButton }]" @click="step -= 1"><font-awesome-icon :icon="['fa', 'arrow-left']" /></button>
                </div>

                <button id="get_started_step_2_help" :class="['cta cta-outline dark bx-s responsive mobile']" @click="set_support_modal_state(true)">{{ $t('getstarted.help') }}</button>

                <button v-if="step === last_step" id="get_started_submit_step_2" :class="['cta cta-circle cta-success arrow bx-s', { loading: loading }]" @click="finalize()"><font-awesome-icon :icon="['fa', 'check']" /></button>
                <button v-else id="get_started_step_2_next" :class="['cta cta-circle cta-primary arrow bx-s', { loading: loading }]" @click="next()"><font-awesome-icon :icon="['fa', 'arrow-right']" /></button>
            </div>
        </div>
        
        <RetirementCalculatorModal :show="retirement_modal.modal" @close_modal="close_modal($event, 'retirement')" @cancel="retirement_modal.modal = false" />
        <RecommendationSalaryModal :show="recommendations.salary.modal" :classification="offer.classification" @cancel="recommendations.salary.modal = false" />
        <RecommendationBenefitsModal :show="recommendations.benefits.modal" :classification="offer.classification" @cancel="recommendations.benefits.modal = false" />
    </div>
</template>

<script>
import cookies from 'js-cookie';
import { mapGetters, mapActions } from 'vuex';

import FormError from '../../../components/Utils/FormError.vue'
import WorkDaysComponent from '../../Form/WorkDaysComponent.vue';
import WorkTimesComponent from '../../Form/WorkTimesComponent.vue';
//import HybridComponent from '../../Form/HybridComponent.vue';
import ClassificationComponent from '../../Form/ClassificationComponent.vue';
//import BenefitsComponent from '../../Form/BenefitsComponent.vue';
import RetirementCalculatorModal from '../../Modals/GetStarted/RetirementCalculatorModal.vue';
import RecommendationSalaryModal from '../../Modals/GetStarted/RecommendationSalaryModal.vue';
import RecommendationBenefitsModal from '../../Modals/GetStarted/RecommendationBenefitsModal.vue';
import Stepper from "../../../pages/Dashboard/GetStarted/Steps/Stepper.vue";

export default {
    name: "Step2WorkerCard",

    components: {
        FormError,
        WorkDaysComponent,
        WorkTimesComponent,
        //HybridComponent,
        ClassificationComponent,
        //BenefitsComponent,
        RetirementCalculatorModal,
        RecommendationSalaryModal,
        Stepper,
        RecommendationBenefitsModal
    },

    props: {
        profileTypeToCreate: {
            type: String,
            default: ''
        }
    },
    
    data() {
        return {
            step: 1,
            last_step: 6,
            number_of_steps: 6,
            today: new Date(),
            worker: {
                selected_job_type: '',
                distanceRange: 15,
                availableNow: true,
                minimumShiftDuration: 3,
                nextTimeAvailable: new Date(),
                availableUntil: new Date(),
                age_group: '55_and_above',
                priority: 'schedule',
                eligible_for_offers_reserved_for_students: false,
                allowed_contact_methods: {
                    chat: { allowed: true },
                    email: {
                        allowed: true,
                        value: '',
                        externaly_validated: false
                    },
                    phone: {
                        allowed: true,
                        value: '',
                        supports_texts: false,
                        externaly_validated: false
                    },
                },
            },

            offer: {
                salary: {},
                workDays: [],
                workTimes: [],
                hoursPerWeek: '',
                calculated_hours_per_week: 0,
                workLocation: 'regular',
                startDate: null,
                classification: {
                    interests: [],
                    query: '',
                    all: null
                }
            },

            regularOption: false,
            remoteOption: false,
            hybridOption: false,
            bothOption: false,

            retirement_modal: {
                modal: false,
            },

            recommendations: {
                salary: {
                    modal: false
                },

                benefits: {
                    modal: false
                }
            },

            from_retirement_calculator: false,
            retirement_amount: null,

            chooseClassification: null,
            chooseClassificationOptions: ['choose', 'no'],
            age_group_options: ['below_55', '55_and_above'],
            priority_options: ['hours', 'schedule', 'type', 'classification', 'location', 'salary'],

            hybrid: {
                hybrid: false,
                days: {
                    number: 1,
                    period: 'weekly'
                }
            },

            benefits: {
                choices: [],
                other: ''
            },

            validation: {
                worker: {
                    distanceRange: {
                        error: false,
                        name: 'worker-distanceRange',
                        key: 'errors.validation.fieldMandatory'
                    },
                    
                    age_group: {
                        error: false,
                        name: 'worker-age_group',
                        key: 'errors.validation.fieldMandatory'
                    },

                    priority: {
                        error: false,
                        name: 'worker-priority',
                        key: 'errors.validation.fieldMandatory'
                    },

                    nextTimeAvailable: {
                        error: false,
                        name: 'worker-priority',
                        key: 'errors.validation.fieldMandatory'
                    },

                    phone_number: {
                        error: false,
                        name: 'worker-phone-number',
                        key: 'errors.validation.fieldMandatory'
                    }
                },

                offer: {
                    salary: {
                        error: false,
                        name: 'worker-salary',
                        key: 'errors.validation.fieldMandatory'
                    },

                    workDays: {
                        error: false,
                        name: 'offer-workDays',
                        key: 'errors.validation.fieldMandatory'
                    },

                    classification: {
                        interests: {
                            error: false,
                            name: 'offer-classification-interests',
                            key: 'errors.validation.fieldMandatory'
                        },

                        experienceLevel: {
                            error: false,
                            name: 'offer-classification-experienceLevel',
                            key: 'errors.validation.fieldMandatory'
                        }
                    },

                    hoursPerWeek: {
                        error: false,
                        name: 'offer-hoursPerWeek',
                        key: 'errors.validation.fieldMandatory'
                    },

                    workTimes: {
                        monday: {
                            start: {
                                error: false,
                                name: 'offer-workTimes-monday-start',
                                key: 'errors.validation.timeFormatInvalid.start'
                            },

                            end: {
                                error: false,
                                name: 'offer-workTimes-monday-end',
                                key: 'errors.validation.timeFormatInvalid.end'
                            }
                        },

                        tuesday: {
                            start: {
                                error: false,
                                name: 'offer-workTimes-tuesday-start',
                                key: 'errors.validation.timeFormatInvalid.start'
                            },

                            end: {
                                error: false,
                                name: 'offer-workTimes-tuesday-end',
                                key: 'errors.validation.timeFormatInvalid.end'
                            }
                        },

                        wednesday: {
                            start: {
                                error: false,
                                name: 'offer-workTimes-wednesday-start',
                                key: 'errors.validation.timeFormatInvalid.start'
                            },

                            end: {
                                error: false,
                                name: 'offer-workTimes-wednesday-end',
                                key: 'errors.validation.timeFormatInvalid.end'
                            }
                        },

                        thursday: {
                            start: {
                                error: false,
                                name: 'offer-workTimes-thursday-start',
                                key: 'errors.validation.timeFormatInvalid.start'
                            },

                            end: {
                                error: false,
                                name: 'offer-workTimes-thursday-end',
                                key: 'errors.validation.timeFormatInvalid.end'
                            }
                        },

                        friday: {
                            start: {
                                error: false,
                                name: 'offer-workTimes-friday-start',
                                key: 'errors.validation.timeFormatInvalid.start'
                            },

                            end: {
                                error: false,
                                name: 'offer-workTimes-friday-end',
                                key: 'errors.validation.timeFormatInvalid.end'
                            }
                        },

                        saturday: {
                            start: {
                                error: false,
                                name: 'offer-workTimes-saturday-start',
                                key: 'errors.validation.timeFormatInvalid.start'
                            },

                            end: {
                                error: false,
                                name: 'offer-workTimes-saturday-end',
                                key: 'errors.validation.timeFormatInvalid.end'
                            }
                        },

                        sunday: {
                            start: {
                                error: false,
                                name: 'offer-workTimes-sunday-start',
                                key: 'errors.validation.timeFormatInvalid.start'
                            },

                            end: {
                                error: false,
                                name: 'offer-workTimes-sunday-end',
                                key: 'errors.validation.timeFormatInvalid.end'
                            }
                        }
                    },

                    workLocation: {
                        error: false,
                        name: 'offer-workLocation',
                        key: 'errors.validation.fieldMandatory'
                    }
                },

                hybrid: {
                    days: {
                        number: {
                            error: false,
                            name: 'hybrid-days-number',
                            key: 'errors.validation.fieldMandatory'
                        }
                    }
                },

                general: {
                    chooseClassification: {
                        error: false,
                        name: 'general-chooseClassification',
                        key: 'errors.validation.fieldMandatory'
                    }
                }
            },

            workLocationOptions: ['remote', 'regular'],

            sectorOptions: [],
            classOptions: [],
            jobOptions: [],

            formValidated: false,
            loading: false,
            profileTypeCreation: false
        }
    },

    computed: {
        ...mapGetters([
            'currentLanguage',
            'context',
            'email'
        ]),

        showBackButton: function() {
            const self = this; return self.context === 'onboarding';
        },

        hourly_salary_to_yearly: function() {
            const self = this;

            if(self.offer.hoursPerWeek !== '' && self.offer.hoursPerWeek > 0) {
                const formatted_salary = self.formatted_hourly_salary(self.offer.salary.amount, false);
                return (self.offer.hoursPerWeek * formatted_salary) * 50;
            }

            return 0;
        }
    },

    watch: {
        'offer.workTimes':  {
            deep: true,
            handler() {
                const self = this;
                if(self.offer.workTimes.length > 0) {
                    if(!isNaN(self.$utils._calculate_hours_per_week_from_work_times(self.offer.workTimes))) {
                        self.offer.calculated_hours_per_week = self.$utils._calculate_hours_per_week_from_work_times(self.offer.workTimes);
                    }else {
                        self.offer.calculated_hours_per_week = 0;
                    }
                }else {
                    self.offer.calculated_hours_per_week = 0;
                }
            }
        },

        profileTypeToCreate: function() {
             this.setProfileType()
        },

        hybridOption: function() {
            const self = this;
            self.hybrid.hybrid = self.hybridOption
            if(self.hybridOption) {
                self.regularOption = false; 
                self.remoteOption = false; 
                self.bothOption = false; 
                
                if(self.validation.offer.workLocation.error === true) {
                    self.validation.offer.workLocation.error = false;
                }
            }
            self.setWorkLocation();
        },

        remoteOption: function() {
            const self = this;
            if(self.remoteOption) { 
                self.hybridOption = false; 
                self.regularOption = false; 
                self.bothOption = false; 

                if(self.validation.offer.workLocation.error === true) {
                    self.validation.offer.workLocation.error = false;
                }
            }
            self.setWorkLocation();
        },

        regularOption: function() {
            const self = this;
            if(self.regularOption) { 
                self.hybridOption = false; 
                self.remoteOption = false; 
                self.bothOption = false;

                if(self.validation.offer.workLocation.error === true) {
                    self.validation.offer.workLocation.error = false;
                }
            }
            self.setWorkLocation();
        },

        bothOption: function() {
            const self = this;
            if(self.bothOption) { 
                self.hybridOption = false; 
                self.remoteOption = false; 
                self.regularOption = false;

                if(self.validation.offer.workLocation.error === true) {
                    self.validation.offer.workLocation.error = false;
                }
            }
            self.setWorkLocation();
        },
        
        chooseClassification: function(choice) {
            const self = this;
            
            self.offer.classification.all = choice === 'no';
            self.offer.classification.interests = [];
            self.offer.classification.query = '';

            if(self.chooseClassification !== '' || self.chooseClassification !== null || self.chooseClassification !== undefined) {
                if(self.validation.general.chooseClassification.error === true) {
                    self.validation.general.chooseClassification.error = false;
                }
            }
        },

        'retirement_modal.modal': function() {
            const self = this;
            if(self.retirement_modal.modal) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        },

        'recommendations.salary.modal': function() {
            const self = this;
            if(self.recommendations.salary.modal) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        },

        'recommendations.benefits.modal': function() {
            const self = this;
            if(self.recommendations.benefits.modal) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        },

        'worker.distanceRange': function() {
            const self = this;
            if(self.worker.distanceRange !== '' && self.worker.distanceRange !== 0) {
                if(self.validation.worker.distanceRange.error === true) { self.validation.worker.distanceRange.error = false; }
            }
        },

        'offer.salary.type': function() {
            const self = this;

            if(self.offer.salary.type === 'hourly') {
                self.offer.salary.amount = self.$CONSTANTS.MINIMUM_SALARY;
            } else if (self.offer.salary.type === 'yearly') {
                self.offer.salary.amount = '12000';
            }
        },
        
        'worker.allowed_contact_methods.phone.allowed': function() {
            const self = this;
            if(!self.worker.allowed_contact_methods.phone.allowed) {
                self.worker.allowed_contact_methods.phone.value = '';
                self.worker.allowed_contact_methods.phone.supports_texts = false;
                self.validation.worker.phone_number.error = false;
            }
        },
    },

    methods: {
        ...mapActions([
            'change_current_step',
            'setToken',
            'set_support_modal_state'
        ]),

        capture_query: function() {
            const self = this;

            if(self.$route.query && self.$route.query.step) {
                const step = parseFloat(self.$route.query.step);
                if(typeof step === 'number' && step >= 1 && step <= self.last_step) { self.step = step }
            }
        },

        formatted_hourly_salary: function(amount, replace) {
            if(!amount.includes('.') && (amount.length === 4 || amount.length === 5)) {
                if(replace) { return `${amount.slice(0, amount.length - 2)},${amount.slice(amount.length - 2)} $` }
                return `${amount.slice(0, amount.length - 2)}.${amount.slice(amount.length - 2)}`
            }

            return amount;
        },

        next: function() {
            const self = this;
            self.validate_mandatory_fields(self.step);
            if(self.formValidated) { 
                self.step += 1 
            }
        },

        validate_step_1: function() {
            const self = this;

            if(!self.worker.availableNow) {
                if(!self.worker.nextTimeAvailable) {
                    self.validation.worker.nextTimeAvailable.error = true;
                    self.formValidated = false;
                }
            }
        },

        validate_step_2: function() {
            const self = this;

            if(!self.offer.workDays || self.offer.workDays.length === 0) {
                self.validation.offer.workDays.error = true
                self.formValidated = false;
            }
        },

        validate_step_3: function() {
            const self = this;

            if(!self.offer.workDays || self.offer.workDays.length === 0) {
                self.validation.offer.workDays.error = true
                self.formValidated = false;
                self.step = 2;
            }

            const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
            days.forEach(day => {
                if(self.offer.workDays.includes(day)) {
                    const slots = ['start', 'end']
                    const period = self.offer.workTimes.find(time => time.day === day)
                    slots.forEach(slot => {
                        if(period.timeSlots[0][slot].value === '' || !self.$utils._validate_time_slot(period.timeSlots[0][slot].value)) {
                            self.validation.offer.workTimes[day][slot].error = true
                            self.validation.offer.workTimes[day][slot].key = `validation.timeFormatInvalid.${slot}`
                        }
                    })
                }
            })

            if(!self.offer.hoursPerWeek || parseFloat(self.offer.hoursPerWeek) === 0) {
                self.validation.offer.hoursPerWeek.error = true;
                self.formValidated = false;
            }else if(parseFloat(self.offer.hoursPerWeek) > self.offer.calculated_hours_per_week) {
                self.validation.offer.hoursPerWeek.error = true;
                self.validation.offer.hoursPerWeek.key = 'errors.validation.hours_per_week_too_high';
                self.formValidated = false;
            }
        },

        // validate_step_4: function() {
        //     const self = this;

        //     if(!self.offer.workLocation || self.offer.workLocation === '') {
        //         self.validation.offer.workLocation.error = true;
        //         self.formValidated = false;
        //     }

        //     if(self.hybrid.hybrid) {
        //         if(!self.hybrid.days.number) {
        //             self.validation.hybrid.days.number.error = true;
        //             self.formValidated = false;
        //         }
        //     }

        //     if(self.offer.workLocation === 'regular' || self.offer.workLocation === 'both') {
        //         if(!self.worker.distanceRange || self.worker.distanceRange === '' || self.worker.distanceRange === 0) {
        //             self.validation.worker.distanceRange.error = true;
        //             self.formValidated = false;
        //         }
        //     }
        // },

        validate_step_4: function() {
            const self = this;
            if(self.offer.classification.all === false) {
                if(self.offer.classification.interests.length === 0) {
                    self.validation.offer.classification.interests.error = true;
                    self.formValidated = false;
                }
            }

            if(!self.chooseClassification) {
                self.validation.general.chooseClassification.error = true;
                self.formValidated = false;
            }
        },

        validate_step_5: function() {
            const self = this;
            if(self.offer.salary.amount === '0' || self.offer.salary.amount === '') {
                self.validation.offer.salary.error = true;
                self.formValidated = false;
            }

            if(self.offer.salary.type && self.offer.salary.amount && self.offer.salary.type === 'hourly' && !self.$utils._validate_hourly_salary(self.offer.salary.amount)) {
                self.validation.offer.salary.error = true;
                self.validation.offer.salary.key = 'errors.validation.salaryTooLow';
                self.formValidated = false;
            }
        },

        validate_step_6: function() {
            const self = this;
            if(self.worker.allowed_contact_methods.phone.allowed) {
                if(!self.$utils._validate_phone_number(self.worker.allowed_contact_methods.phone.value)) {
                    self.validation.worker.phone_number.error = true;
                    self.formValidated = false;
                }
            }
        },

        validate_mandatory_fields: function(step) {
            const self = this;
            
            self.reset_validation();
            
            if(step === 1) { self.validate_step_1();  }
            else if (step === 2) { self.validate_step_2(); }
            else if (step === 3) { self.validate_step_3(); }
            else if (step === 4) { self.validate_step_4(); }
            else if (step === 5) { self.validate_step_5(); }
            else if (step === 6) { self.validate_step_6(); }
        },

        reset_validation: function() {
            const self = this;

            self.formValidated = true;

            ['worker', 'offer'].forEach(key => {
                Object.keys(self.validation[key]).forEach(val => {
                    if(val === 'workTimes') {
                        const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
                        days.forEach(day => {
                            self.validation.offer.workTimes[day].start.error = false;
                            self.validation.offer.workTimes[day].end.error = false;
                        })
                    } else if (val === 'classification') {
                        ['interests'].forEach(key => {
                            self.validation.offer.classification[key].error = false;
                        })
                    } else {
                        self.validation[key][val].error = false
                    }
                 });
            })

            self.validation.hybrid.days.number.error = false;
            self.validation.general.chooseClassification.error = false;
            self.validation.offer.hoursPerWeek.key = 'errors.validation.fieldMandatory';
            self.validation.offer.salary.key = 'errors.validation.fieldMandatory';
        },

        setWorkLocation: function() {
            const self = this;

            self.offer.workLocation = '';
            
            if(self.hybridOption) { self.offer.workLocation = 'regular' }
            else if(self.regularOption) { self.offer.workLocation = 'regular' }
            else if(self.remoteOption) { self.offer.workLocation = 'remote' }
            else if(self.bothOption) { self.offer.workLocation = 'both' }
        },

        validate_all: function() {
            const self = this;  

            self.validate_step_1();
            self.validate_step_2();
            self.validate_step_3();
            self.validate_step_4();
            self.validate_step_5();
            self.validate_step_6();
        },

        finalize: function() {
            const self = this;

            self.validate_mandatory_fields(self.step);
            if(self.formValidated) {
                self.loading = true;

                if(self.worker.selected_job_type === 'temporary') {
                    self.worker.availableNow = false;
                    self.offer.startDate = self.worker.nextTimeAvailable;
                    self.offer.endDate = self.worker.availableUntil;
                }

                if(self.worker.selected_job_type === 'permanent') {
                    if(self.worker.availableNow) {
                        self.worker.nextTimeAvailable = null;
                        self.worker.availableUntil = null;
                        self.offer.startDate = new Date()
                        self.offer.endDate = null;
                    }else {
                        self.worker.availableNow = false;
                        self.offer.startDate = self.worker.nextTimeAvailable;
                        self.offer.endDate = null;
                        self.worker.availableUntil = null;
                    }
                }

                if(self.offer.workLocation === 'remote') {
                    self.worker.distanceRange = 15;
                } else if(self.offer.workLocation === 'regular') {
                    self.offer.hybrid = self.hybrid;
                }

                self.offer.calculated_hours_per_week = self.$utils._calculate_hours_per_week_from_work_times(self.offer.workTimes);
                
                if(self.worker.eligible_for_offers_reserved_for_students) {
                    self.worker.age_group = 'below_55';
                }

                const data = {
                    worker: {
                        distanceRange: self.worker.distanceRange,
                        availableNow: self.worker.availableNow,
                        minimumShiftDuration: self.worker.minimumShiftDuration,
                        nextTimeAvailable: self.worker.nextTimeAvailable,
                        age_group: self.worker.age_group,
                        priority: self.worker.priority,
                        eligible_for_offers_reserved_for_students: self.worker.eligible_for_offers_reserved_for_students,
                        isOnboarding: true,
                        allowed_contact_methods: self.worker.allowed_contact_methods,
                        availableUntil: self.worker.availableUntil,
                    },

                    offer: {
                        classification: self.offer.classification,
                        workDays: self.offer.workDays,
                        workTimes: self.offer.workTimes,
                        hoursPerWeek: parseFloat(self.offer.hoursPerWeek),
                        calculated_hours_per_week: self.offer.calculated_hours_per_week,
                        workLocation: self.offer.workLocation,
                        startDate: self.offer.startDate,
                        endDate: self.offer.endDate,
                        salary: self.offer.salary,
                        hybrid: self.offer.hybrid,
                        benefits: self.benefits,
                        managementPosition: false,
                        availableOnCall: false,
                    }
                }

                if(data.worker.allowed_contact_methods.email.allowed) {
                    data.worker.allowed_contact_methods.email.value = self.email;
                }

                if(self.retirement_amount) {
                    data.worker.retirement_amount = self.retirement_amount;
                }

                self.$axios
                    .post('/worker', data)
                    .then(response => {
                        if(response.status == 200) {
                            self.loading = false;

                            self.setToken(response.data);
                        
                            cookies.remove('amount');
                            cookies.remove('from_retirement_calculator');
                            
                            self.$utils._navigate_to_name('welcome');
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                        
                        self.loading = false;
                        self.validate_all();
                        self.step = 1;
                    });
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },
        
        close_modal: function(event, provenance) {
            const self = this;

            if(provenance === 'retirement') {
                self.retirement_modal.modal = false;
                self.from_retirement_calculator = true;
                self.retirement_amount = event.amount;
            }
        },

        handle_blur: function(key) {
            const self = this;

            if(key === 'distanceRange') {
                if(self.worker.distanceRange !== '' && self.worker.distanceRange !== 0) {
                    if(self.validation.worker.distanceRange.error === true) { self.validation.worker.distanceRange.error = false; }
                }
            }
        },

        //utils
        update: function(data) {
            const self = this;
            
            const keys = Object.keys(data);
            const from_hybrid = keys.includes('hybrid');
            keys.forEach(key => {
                if(from_hybrid) {
                    self.hybrid = data.hybrid;
                }else {
                    if(key === 'validation') { self.validation.offer = data[key] }
                    else { self.offer[key] = data[key] }
                }
            });
        },

        setProfileType: function() {
            const self = this;
            if(self.profileTypeToCreate !== '') {
                self.profileTypeCreation = true;
            }
        },

        error: function(type, key) {
            try {
                return { error: this.validation[type][key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${type}, ${key}`)
            }
        },

        translate_choose_classification: function(key) {
            const self = this;
            return self.$t(`workerOffer.card.create.classification.choose.options.${key}`)
        },

        translate_age_group: function(key) {
            const self = this;
            return self.$t(`profile.worker.card.age_group.values.${key}`)
        },

        display_notice: function() {
            const self = this;
            self.$toasted.info(
                self.$t('getstarted.steps.seven.notice'),
                { icon: 'circle-exclamation' }
            );
        }
    },

    mounted() {
        const self = this;
        self.setProfileType();

        const from_retirement_calculator = cookies.get('from_retirement_calculator');
        if(from_retirement_calculator) {
            self.from_retirement_calculator = true;
            self.worker.age_group = '55_and_above';
            self.retirement_amount = parseFloat(cookies.get('amount'));
        }
        
        self.offer.salary = {
            type: 'hourly',
            amount: self.$CONSTANTS.MINIMUM_SALARY
        }

        self.capture_query();
    }
};
</script>