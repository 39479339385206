<template>
    <div v-if="modal" class="modal recommendations-benefits-modal" :class="{ active: modal }">
        <div class="modal-card">
            <BenefitsCard :sector="classification.sector" :job_class="classification.class" :valid_recommendations="true" type="onboarding" @cancel="cancel()" />
        </div>
    </div>
</template>

<script>
import BenefitsCard from '../../Cards/Offers/WorkProvider/Recommendations/BenefitsCard.vue';

export default {
    name: 'RecommendationBenefitsModal',

    components: { BenefitsCard },

    props: {
        show: {
            type: Boolean,
            default: false
        },

        classification: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    watch: {
        show: function(val) {
            this.modal = val;
        }
    },

    data() {
        return {
            modal: false,
        }
    },
    
    methods: {
        cancel: function() {
            const self = this;

            self.modal = false;
            self.$emit('cancel');
        }
    },
}
</script>

