<template>
    <div :class="[`stepper-wrapper top-${numberOfSteps}`, { top }]">
        <div class="progress-bar">
            <div id="stepper-step-1" class="pill first active"></div>
            <div :id="`stepper-step-${index + 1}`" class="pill" v-for="index in (numberOfSteps - 2)" :key="index"></div>
            <div :id="`stepper-step-${numberOfSteps}`" class="pill last"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Stepper',
    props: {
        numberOfSteps: {
            type: Number,
            default: 3
        },
        step: {
            type: Number,
            default: 1
        },
        top: {
            type: Boolean,
            default: false
        }
    },

    watch: { 
        step: function(step) {
            this.setupStepper(step)
        }
    },

    methods: {
        setupStepper: function(step) {
            const self = this;
            for (let i = 0; i < self.numberOfSteps; i++) {
                const pill = document.getElementById(`stepper-step-${i + 1}`);
                
                if(i + 1 <= step){
                    pill.classList.add('active');
                }

                if(i + 1 > step){
                    pill.classList.remove('active');
                }
            } 
        }
    },

    mounted() {
        this.setupStepper(this.step)
    }
};
</script>